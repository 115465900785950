<template>
  <BaseTableLoader :loading="loading">
    <v-data-table
      :expanded="tableMixin_expandedRows"
      :footer-props="{
        'items-per-page-options': [pagination.rowsPerPage],
      }"
      :headers="tableMixin_displayedHeaders"
      :items="rows"
      :mobile-breakpoint="0"
      :page="pagination.page"
      :server-items-length="pagination.totalItems"
      disable-sort
      @click:row="onRowClick"
      @update:page="tableMixin_changePage"
    >
      <template v-slot:item.name="{ item }">
        <div>
          {{ item.name }}
        </div>
        <div v-if="item.persons.length" class="grey--text clickable">
          {{ $t('contact_persons') }} ({{ item.persons.length }})
        </div>
      </template>

      <template v-slot:item.logo_file_url="{ item }">
        <v-img
          v-if="item.logo_file_url"
          :src="item.logo_file_url"
          max-height="40px"
          max-width="80px"
          contain
        />
      </template>

      <template v-slot:item.address="{ item }">
        <a :href="`https://maps.google.com/?q=${item.address}`" target="_blank" class="link">
          {{ item.address }}
        </a>
      </template>

      <template v-slot:item.website="{ item }">
        <a :href="getWebsiteUrl(item.website)" target="_blank" class="link">
          {{ item.website }}
        </a>
      </template>

      <template v-slot:item.is_client="{ item }">
        <span>
          <v-icon v-if="item.is_client" color="success">
            mdi-checkbox-marked-circle-outline
          </v-icon>
        </span>
      </template>

      <template v-slot:item.is_supplier="{ item }">
        <span>
          <v-icon v-if="item.is_supplier" color="success">
            mdi-checkbox-marked-circle-outline
          </v-icon>
        </span>
      </template>

      <template v-slot:item.tags="{ item }">
        <div class="mt-1">
          <v-chip v-for="tag in item.tags" :key="tag" class="mb-1" small>{{ tag }}</v-chip>
        </div>
      </template>

      <template v-slot:item.comments="{ item }">
        <BaseTruncatedField :text="getContactComments(item)" :truncated-length="50" />
      </template>

      <template v-slot:item.actions="{ item }">
        <BaseActionMenu :actions="actions" :item="item" />
      </template>

      <template v-slot:expanded-item="{ item }">
        <BaseExpandedTableRow
          :colspan="tableMixin_displayedHeaders.length"
          :headers="tableMixin_hiddenHeaders"
          :item="item"
        >
          <template v-slot:item.logo_file_url>
            <v-img
              v-if="item.logo_file_url"
              :src="item.logo_file_url"
              max-height="40px"
              max-width="80px"
              contain
            />
          </template>

          <template v-slot:item.address>
            <a :href="`https://maps.google.com/?q=${item.address}`" target="_blank" class="link">
              {{ item.address }}
            </a>
          </template>

          <template v-slot:item.website>
            <a :href="getWebsiteUrl(item.website)" target="_blank" class="link">
              {{ item.website }}
            </a>
          </template>

          <template v-slot:item.is_client>
            <span>
              <v-icon v-if="item.is_client" color="success">
                mdi-checkbox-marked-circle-outline
              </v-icon>
            </span>
          </template>

          <template v-slot:item.is_supplier>
            <span>
              <v-icon v-if="item.is_supplier" color="success">
                mdi-checkbox-marked-circle-outline
              </v-icon>
            </span>
          </template>

          <template v-slot:item.tags>
            <div class="mt-1">
              <v-chip v-for="tag in item.tags" :key="tag" class="mb-1" small>{{ tag }}</v-chip>
            </div>
          </template>

          <template v-slot:append>
            <v-divider v-if="tableMixin_hiddenHeaders.length && item.persons.length" />
            <v-row v-for="(person, index) in item.persons" :key="person.id" class="my-3" dense>
              <v-col v-if="person.name" cols="12" sm="6">
                <div class="subtitle-2">
                  {{ $t('name') }}
                </div>
                {{ person.name }}
              </v-col>

              <v-col v-if="person.email" cols="12" sm="6">
                <div class="subtitle-2">
                  {{ $t('email') }}
                </div>
                <a :href="`mailto:${person.email}`" class="link">
                  {{ person.email }}
                </a>
              </v-col>

              <v-col v-if="person.phone" cols="12" sm="6">
                <div class="subtitle-2">
                  {{ $t('phone') }}
                </div>
                <a :href="`tel:${person.phone}`" class="link">
                  {{ person.phone }}
                </a>
              </v-col>

              <v-col v-if="person.comment" cols="12" sm="6">
                <div class="subtitle-2">
                  {{ $t('comment') }}
                </div>
                {{ person.comment }}
              </v-col>

              <v-col cols="12">
                <v-divider v-if="index < item.persons.length - 1" class="mt-3" />
              </v-col>
            </v-row>
          </template>
        </BaseExpandedTableRow>
      </template>
    </v-data-table>
  </BaseTableLoader>
</template>

<script>
import BaseActionMenu from '../base/BaseActionMenu';
import BaseExpandedTableRow from '../base/BaseExpandedTableRow';
import BaseTableLoader from '../base/BaseTableLoader';
import tableMixin from '../../mixins/table-mixin';
import BaseTruncatedField from '@/components/base/BaseTruncatedField';

export default {
  name: 'ContactTable',

  components: {
    BaseTruncatedField,
    BaseTableLoader,
    BaseExpandedTableRow,
    BaseActionMenu,
  },

  mixins: [tableMixin],

  props: {
    rows: Array,
    pagination: Object,
    loading: {
      type: Boolean,
      default: false,
    },
    contactType: {
      type: String,
      default: 'client',
    },
  },

  data() {
    return {
      actions: [
        {
          callback: p => this.$emit('edit', p),
          label: this.$t('edit'),
          icon: 'mdi-pencil',
        },
        {
          callback: p => this.$emit('delete', p),
          label: this.$t('delete'),
          icon: 'mdi-delete',
        },
      ],
      headers: [
        {
          text: this.$t('name'),
          value: 'name',
        },
        {
          text: this.$t('logo'),
          value: 'logo_file_url',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('address'),
          value: 'address',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('website'),
          value: 'website',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('is_client'),
          value: 'is_client',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('is_supplier'),
          value: 'is_supplier',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('tags'),
          value: 'tags',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('comments'),
          value: 'comments',
          hidden: 'mdAndDown',
        },
        {
          value: 'actions',
        },
      ],
    };
  },

  computed: {
    title() {
      return this.$t(`${this.contactType}s`);
    },

    createButtonText() {
      return this.$t(`create_${this.contactType}`);
    },
  },

  methods: {
    getContactComments(contact) {
      return contact.persons.map(p => p.comment).join(';\n');
    },

    getWebsiteUrl(website) {
      if (!website) {
        return '';
      }
      return /https?:\/\//.test(website) ? website : `http://${website}`;
    },

    onRowClick(contact) {
      if (!contact.persons.length && !this.tableMixin_hiddenHeaders.length) {
        return;
      }
      this.tableMixin_expandRow(contact);
    },
  },
};
</script>
