<template>
  <div class="d-flex pa-4">
    <h1 class="title">
      {{ title }}
    </h1>
    <slot>
      <v-spacer />
      <BasePrimaryActionButton
        v-if="createButton"
        :icon="createButtonIcon"
        :label="createButton"
        @click="$emit('new-item')"
      />
      <slot name="append" />
    </slot>
  </div>
</template>

<script>
import BasePrimaryActionButton from './BasePrimaryActionButton';

export default {
  name: 'BaseTableHeader',

  components: { BasePrimaryActionButton },

  props: {
    title: {
      type: String,
      default: '',
    },

    createButton: {
      type: String,
      default: '',
    },

    createButtonIcon: {
      type: String,
      default: 'mdi-plus',
    },
  },
};
</script>

<style scoped></style>
