<template>
  <v-form @submit.prevent="onSubmit">
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="contact.name"
              :error-messages="errors['name']"
              :label="formMixin_getRequiredFieldLabel($t('name'))"
              @blur="formMixin_clearErrors('name')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="contact.code"
              :error-messages="errors['code']"
              :label="$t('code')"
              @blur="formMixin_clearErrors('code')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="contact.vat_code"
              :error-messages="errors['vat_code']"
              :label="$t('invoices.general.vat_code')"
              @blur="formMixin_clearErrors('vat_code')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-textarea
              v-model="contact.address"
              :error-messages="errors['address']"
              :label="$t('address')"
              rows="1"
              auto-grow
              @blur="formMixin_clearErrors('address')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="contact.phone"
              :error-messages="errors['phone']"
              :label="$t('phone')"
              @blur="formMixin_clearErrors('phone')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="contact.email"
              :error-messages="errors['email']"
              :label="$t('email')"
              @blur="formMixin_clearErrors('email')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="contact.email_for_invoices"
              :error-messages="errors['email_for_invoices']"
              :label="$t('email_for_invoices')"
              @blur="formMixin_clearErrors('email_for_invoices')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="contact.invoice_due_days"
              :error-messages="errors['invoice_due_days']"
              :label="formMixin_getRequiredFieldLabel($t('invoice_due_days'))"
              type="number"
              @blur="formMixin_clearErrors('invoice_due_days')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-file-input
              v-model="contact.logo"
              :error-messages="errors['logo']"
              :label="$t(contact.id && contact.logo_file_url ? 'choose_new_logo' : 'logo')"
              accept=".jpg,.jpeg,.png,.bmp,.gif"
              @blur="formMixin_clearErrors('logo')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="contact.website"
              :error-messages="errors['website']"
              :label="$t('website')"
              @blur="formMixin_clearErrors('website')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-checkbox
              v-model="contact.is_client"
              :error-messages="errors['is_client']"
              :label="$t('is_client')"
              @blur="formMixin_clearErrors('is_client')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-checkbox
              v-model="contact.is_supplier"
              :error-messages="errors['is_supplier']"
              :label="$t('is_supplier')"
              @blur="formMixin_clearErrors('is_supplier')"
            />
          </v-col>

          <v-col cols="12">
            <v-combobox
              v-model="contact.tags"
              :label="$t('tags')"
              :items="tags"
              multiple
              chips
              small-chips
              deletable-chips
            />
          </v-col>
        </v-row>

        <v-row v-for="(person, index) in contact.persons" :key="index" dense>
          <v-col cols="12" class="d-flex justify-space-between mt-3">
            <h3 class="subtitle-1 black--text">
              {{ $t('contact_person') }} {{ $t('number_short') }} {{ index + 1 }}
            </h3>
            <v-btn icon @click="contact.persons.splice(index, 1)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="person.name"
              :error-messages="errors[`persons.${index}.name`]"
              :label="$t('name')"
              @blur="formMixin_clearErrors(`persons.${index}.name`)"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="person.email"
              :error-messages="errors[`persons.${index}.email`]"
              :label="$t('email')"
              @blur="formMixin_clearErrors(`persons.${index}.email`)"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="person.phone"
              :error-messages="errors[`persons.${index}.phone`]"
              :label="$t('phone')"
              @blur="formMixin_clearErrors(`persons.${index}.phone`)"
            />
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="person.comment"
              :error-messages="errors[`persons.${index}.comment`]"
              :label="$t('comment')"
              rows="1"
              auto-grow
              @blur="formMixin_clearErrors(`persons.${index}.comment`)"
            />
          </v-col>
        </v-row>

        <div class="text-center">
          <v-btn color="primary" id="add-contact-person-btn" text @click="addContactPerson">
            <v-icon left>mdi-plus</v-icon>
            {{ $t('add_contact_person') }}
          </v-btn>
        </div>
      </v-card-text>

      <v-card-actions>
        <span v-if="!contact.id" class="subtitle-2 ml-3"> * {{ $t('must_be_filled') }} </span>

        <v-spacer />

        <v-btn color="primary" text @click.native="$emit('cancel')">
          {{ $t('cancel') }}
        </v-btn>

        <v-btn
          :disabled="isRequestPending"
          :loading="isRequestPending"
          color="primary"
          type="submit"
          text
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import crudMixin from '../../mixins/crud-mixin';
import dialogMixin from '../../mixins/dialog-mixin';
import formMixin from '../../mixins/form-mixin';
import contactService from '../../api/contact-service';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'ContactForm',

  mixins: [crudMixin, dialogMixin, formMixin],

  props: {
    contactType: {
      type: String,
      default: 'client',
    },
    formItem: Object,
  },

  data() {
    return {
      errors: {},
      isRequestPending: false,
      contact: {},
    };
  },

  computed: {
    ...mapState('tags', ['tags']),

    formTitle() {
      return this.$t(this.contact.id ? `edit_${this.contactType}` : `new_${this.contactType}`);
    },
  },

  methods: {
    ...mapMutations('tags', ['SET_TAGS']),

    async addContactPerson() {
      this.contact.persons.push({});
      await this.$nextTick();
      document.querySelector('#add-contact-person-btn').scrollIntoView();
    },

    onDialogOpen() {
      this.contact = JSON.parse(JSON.stringify(this.formItem));
      if (!this.contact.tags) {
        this.$set(this.contact, 'tags', []);
      }
      this.errors = {};
    },

    async onSubmit() {
      const createMsg = this.$t(`${this.contactType}_created`);
      const updateMsg = this.$t(`${this.contactType}_updated`);
      await this.crudMixin_createOrUpdate(contactService, this.contact, createMsg, updateMsg);

      // Update tags array with newly entered tags
      const tagSet = new Set([...this.tags, ...this.contact.tags]);
      this.SET_TAGS(Array.from(tagSet));
    },
  },
};
</script>
