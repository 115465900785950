<template>
  <v-row class="px-4 pb-4">
    <v-col cols="12" sm="6" md="4" lg="3">
      <v-text-field
        :value="filters.name"
        :label="$t('search')"
        prepend-icon="mdi-magnify"
        hide-details
        clearable
        @input="onNameInput"
      />
    </v-col>

    <v-col cols="12" sm="6" md="8" lg="5">
      <v-combobox
        :value="filters.tags"
        :label="$t('tags')"
        :items="tags"
        multiple
        chips
        deletable-chips
        small-chips
        hide-details
        @input="updateFilter('tags', $event)"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ContactFilter',

  props: {
    filters: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      timerID: null,
    };
  },

  computed: {
    ...mapState('tags', ['tags']),
  },

  methods: {
    onNameInput(value) {
      clearTimeout(this.timerID);
      this.timerID = setTimeout(() => {
        this.updateFilter('name', value);
      }, 500);
    },

    updateFilter(key, value) {
      this.$emit('change', {
        ...this.filters,
        [key]: value,
      });
    },
  },
};
</script>

<style scoped></style>
