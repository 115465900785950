<template>
  <div class="page-wrapper">
    <BaseTableHeader
      :title="title"
      :create-button="createButtonText"
      @new-item="crudMixin_openForm('contact', newContactTemplate)"
    />

    <ContactFilter :filters="contactFilterParams" @change="updateFilter" />

    <ContactTable
      :rows="contactArray"
      :pagination="contactPagination"
      :loading="isDataLoading"
      :contact-type="pageType"
      @change-page="getPaginatedContacts"
      @edit="crudMixin_openForm('contact', $event)"
      @delete="deleteContact"
    />

    <v-dialog
      v-model="isContactFormOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="800"
      persistent
      scrollable
    >
      <ContactForm
        :dialog="isContactFormOpen"
        :form-item="contactFormItem"
        :contact-type="pageType"
        @create="contactCreated"
        @update="contactUpdated"
        @cancel="isContactFormOpen = false"
      />
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import ContactForm from '../components/forms/ContactForm';
import ContactTable from '../components/tables/ContactTable';
import crudMixin from '../mixins/crud-mixin';
import contactService from '../api/contact-service';
import ContactFilter from '@/components/filters/ContactFilter';
import BaseTableHeader from '@/components/base/BaseTableHeader';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Contacts',

  components: {
    BaseTableHeader,
    ContactFilter,
    ContactForm,
    ContactTable,
  },

  mixins: [crudMixin],

  data() {
    return {
      contactArray: [],
      contactPagination: {
        page: 1,
      },
      contactFormItem: {},
      contactFilterParams: {
        tags: [],
      },
      isContactFormOpen: false,
      isDataLoading: true,
      newContactTemplate: {
        is_client: false,
        is_supplier: false,
        persons: [],
        invoice_due_days: 14,
      },
      pageType: 'client',
    };
  },

  computed: {
    ...mapState('tags', ['tags']),

    createButtonText() {
      return this.$t(`create_${this.pageType}`);
    },

    title() {
      return this.$t(`${this.pageType}s`);
    },
  },

  // Can't use created hook like usual, because it won't fire when navigating from suppliers
  // to clients and vice versa, because they both use the same view.
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // in beforeRouteEnter we don't have access to 'this'. https://router.vuejs.org/guide/advanced/navigation-guards.html#in-component-guards
      vm.isDataLoading = true;
      if (to.name === 'clients') {
        vm.pageType = 'client';
        vm.newContactTemplate.is_client = true;
        vm.newContactTemplate.is_supplier = false;
      } else {
        vm.pageType = 'supplier';
        vm.newContactTemplate.is_client = false;
        vm.newContactTemplate.is_supplier = true;
      }
      vm.getPaginatedContacts(1);
    });
  },

  created() {
    if (!this.tags.length) {
      this.fetchTags();
    }
  },

  methods: {
    ...mapActions('tags', ['fetchTags']),

    contactCreated(contact) {
      if (
        (contact.is_supplier && this.pageType === 'supplier') ||
        (contact.is_client && this.pageType === 'client')
      ) {
        this.crudMixin_created('contact', contact);
      }
    },

    contactUpdated(contact) {
      if (
        (!contact.is_supplier && this.pageType === 'supplier') ||
        (!contact.is_client && this.pageType === 'client')
      ) {
        this.contactArray = this.contactArray.filter(c => c.id !== contact.id);
        this.contactPagination.totalItems--;
      } else {
        this.crudMixin_updated('contact', contact);
      }
    },

    deleteContact(contact) {
      const successMsg = this.$t(`${this.pageType}_deleted`);
      this.crudMixin_delete(contactService.delete, 'contact', contact, successMsg);
    },

    getPaginatedContacts(pageNo) {
      if (this.pageType === 'client') {
        this.contactFilterParams.is_client = true;
        delete this.contactFilterParams.is_supplier;
      } else {
        this.contactFilterParams.is_supplier = true;
        delete this.contactFilterParams.is_client;
      }
      this.crudMixin_getPage(
        contactService.getPage,
        contactService.model,
        pageNo,
        this.contactFilterParams,
      );
    },

    updateFilter(filters) {
      this.contactFilterParams = filters;
      this.getPaginatedContacts(1);
    },
  },
};
</script>
